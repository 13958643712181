import { Benefit } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';
import type { FormValues } from '@wix/form-viewer';
import type {
  CheckoutData,
  IntegrationData,
  PlanCustomizationData,
  RestrictedPageParams,
  StatusData,
} from '@wix/pricing-plans-router-utils';
import { ThankYouPageSettings } from '@wix/pricing-plans-utils/client-data';
import type { IUser, WidgetProps } from '@wix/yoshi-flow-editor';
import { TabState } from '../constants/settings-events';
import { FormattedSpannedPrice } from '../utils';

export enum CheckoutStage {
  CHECKOUT_LOADED = 'login-in-checkout_loaded',
  LOGIN_CLICKED = 'auth_method_login',
  SIGNUP_CLICKED = 'auth_method_signup',
  PAYMENT_SECTION_LOADED = 'payment_component_loaded',
  START_DATE_CHANGED = 'start_date_changed',
  GUEST_CHECKOUT_CONTINUE = 'guest_checkout_continue',
  GUEST_CHECKOUT_GET_FREE_PLAN = 'guest_checkout_get_free_plan',
  THANK_YOU_PAGE = 'thank_you_page',
}

export interface TextData {
  ['button-text']?: string;
  ['disclaimer-text']?: string;
  ['free-trial-button-text']?: string;
  ['page-title-text']?: string;
  ['ribbon-text']?: string;
}

export enum MessageCode {
  PURCHASE_LIMIT_ERROR = 1,
  UNKNOWN_ERROR = 2,
  CHECKOUT_DEMO = 3,
  START_DATE_CHANGED = 4,
  START_DATE_UPDATE_FAILED = 5,
  PLAN_NOT_FOUND = 6,
  CHECKOUT_PAYLOAD_DECODE_FAILED = 7,
  STATUS_PAYLOAD_DECODE_FAILED = 8,
  INTEGRATION_DATA_DECODE_FAILED = 9,
  ACCEPT_PAYMENTS_FEATURE_MISSING = 10,
}

export type UserData = Pick<IUser, 'id' | 'loggedIn' | 'role' | 'instance'>;

export type SubPage =
  | { name: 'list'; integrationData: IntegrationData }
  | { name: 'checkout'; checkoutData: CheckoutData }
  | { name: 'customization'; planCustomizationData: PlanCustomizationData }
  | { name: 'status'; statusData: StatusData }
  | { name: 'restricted'; restrictedPageParams: RestrictedPageParams; integrationData: IntegrationData };

export enum ModalType {
  CannotAcceptPayment,
  PurchaseInPreview,
  Upgrade,
  None,
}

export type Modal = AlertModal | UpgradeModal | typeof noModal;

type UpgradeModal = GenericModal<ModalType.Upgrade>;

export const noModal = { type: ModalType.None } as const;

export type AlertModal = GenericModal<ModalType.CannotAcceptPayment> | GenericModal<ModalType.PurchaseInPreview>;

export type GenericModal<T extends ModalType> = { type: T; onClose: () => void };

export interface CommonProps {
  subPage: SubPage;
  shouldUpgrade?: boolean;
  modal: Modal;
  messageCode?: MessageCode;
  tabState: TabState;
  areSettingsOpened: boolean;
  instance?: string;
  appInstanceId: string;
  metaSiteId?: string;
  siteOwnerId?: string;
  visitorId?: string;
  hideToast(): void;
  showToast(message: MessageCode): void;
  biUpgradeReferralClick(referralInfo: string): void;
  navigateToHomePage(): void;
  locale?: string;
  fitToContentHeight?: boolean;
}

export interface ListProps {
  overrideAppTitle?: string;
  overrideAppSubtitle?: string;
  plans: PublicPlan[];
  selectPlan(plan: PublicPlan): void;
  selectedPlanId?: string;
  isMultiPageApp?: boolean;
  widgetLoaded(): void;
  hideToast(): void;
  messageCode?: MessageCode;
  errorMessage?: string;
}

export type PromisifyFn<T extends (...args: any) => any> = (...args: Parameters<T>) => Promise<ReturnType<T>>;
export type CouponInputMode = 'trigger' | 'input' | 'coupon';
export type ErrorMessage = { message: string };
export type OnBeforeStartPaymentFn = (
  orderId: string,
  planId: string,
  options: { startDate?: string; couponCode?: string; formId?: string; formValues?: FormValues },
) => void;
export type CheckoutStatus = { success: boolean };
export type OnGetFullyDiscountedPlanFn = (data: {
  orderId: string;
  plan: PublicPlan;
  couponCode: string;
  integrationData: IntegrationData;
  startDate?: string;
  guestCheckoutEnabled: boolean;
  formId?: string;
  formValues?: FormValues;
}) => void;
export type OnGetFreePlanFn = (data: {
  plan: PublicPlan;
  integrationData: IntegrationData;
  isGuest: boolean;
  guestEmail?: string;
  guestCheckoutEnabled: boolean;
  formId?: string;
  formValues?: FormValues;
}) => void;
export type OnContinueAsGuestFn = (data: { plan: PublicPlan; email?: string; skipOrderCreation?: boolean }) => void;
export interface CheckoutProps {
  purchaseLimitExceeded: boolean;
  user: UserData;
  userEmail?: string;
  selectedPlan: PublicPlan;
  order?: Order;
  prices: FormattedSpannedPrice[];
  benefits: Benefit[];
  loginOnCheckout(): void;
  signupOnCheckout(): void;
  logout(): void;
  navigateToStatus(params: {
    result: PaymentCompleteResultPublic;
    plan: PublicPlan;
    order: Order;
    integrationData: IntegrationData;
    guestCheckoutEnabled: boolean;
  }): void;
  trackInitiateCheckout(): void;
  trackSelectPayment(id: string): void;
  demoBuyNowClicked(): void;
  biCheckoutStage(state: CheckoutStage): void;
  biPlanPurchased(result: PaymentCompleteResultPublic, order: Order, selectedPlan: PublicPlan): void;
  updatePriceDetails(planId: string, couponCode?: string): void;
  updatePriceDetailsError?: ErrorMessage;
  couponInputMode: CouponInputMode;
  couponCode: string;
  couponLoading: boolean;
  removeCoupon: (planId: string) => void;
  onBeforeStartPayment: OnBeforeStartPaymentFn;
  validateSubmission: (formId: string, formValues: FormValues) => Promise<void>;
  onBeforeStartPaymentStatus?: CheckoutStatus;
  updateStartDateError?: ErrorMessage;
  applyCouponError?: ErrorMessage;
  hasCoupons: boolean;
  onGetFreePlan: OnGetFreePlanFn;
  onGetFullyDiscountedPlan: OnGetFullyDiscountedPlanFn;
  zeroPricePlanCheckoutStatus?: CheckoutStatus;
  isCheckoutDataInitialized: boolean;
  onContinueAsGuest: OnContinueAsGuestFn;
  onEditGuestEmail: () => void;
  guestCheckoutEnabled: boolean;
  guestEmail?: string;
  guestEmailConfirmed?: boolean;
  guestEmailError?: 'invalid' | 'already-purchased' | 'required';
  guestCheckoutLoading?: boolean;
  skipAdditionalInfoStep?: boolean;
  integrationData: IntegrationData;
  multilingualCountryCode?: string;
}

export interface StatusProps {
  settings?: ThankYouPageSettings;
  successful?: boolean;
  translatedError?: string;
  order?: Order;
  startDate?: string;
  ownerDemo?: boolean;
  integrationData: IntegrationData;
  metaSiteId?: string;
  navigateBackToCheckout(): void;
  navigateFromStatusPage(): void;
  biThankYouPageCtaButtonClick(): void;
  biThankYouPageOnLoad(): void;
  biUpgradeReferralClick(referralInfo: string): void;
  isOrderProvisioned?: boolean;
}

export interface RestrictedProps {
  missingPlan: boolean;
  switchAccounts(): void;
  navigateToPlanList(): void;
}

export type AppProps = CommonProps & ListProps & CheckoutProps & StatusProps & RestrictedProps;

export type AppWidgetProps = WidgetProps<AppProps>;

export enum BenefitBulletType {
  NONE = 0,
  CIRCLE_TICK = 1,
  HOLLOW_CIRLCE_TICK = 2,
  TICK = 3,
  ARROW = 4,
}
